import './App.css';
import Homepage from './pages/homepage';
import './assets/global.scss';

function App() {
  return (
    <Homepage/>
  );
}


export default App;
